<template>
  <div ref="tree" class="mygoods_zong">
    <div class="head">
      <!-- 头部tab栏 -->
      <div class="hd_tab">
        <div
          v-for="(item, index) in head_list"
          :key="index"
          :class="tab === item.id ? 'head_item_click' : 'head_item'"
          class="head_item"
          @click="change_item(item.id)"
        >
          {{ item.name }}
        </div>
      </div>
      <!-- 中间搜索框 -->
      <div class="hd_search">
        <div class="search_box">
          <div class="txt">商品名称/id</div>
          <input class="inp" v-model="shop_title" />
        </div>

        <div class="search_box">
          <div class="txt"></div>
          <el-button class="search_btn" @click="sub_search">搜索</el-button>
        </div>
        <div class="clear"></div>
      </div>
    </div>
    <!-- 批量操作商品 -->
    <div class="goods_edit">
      <el-button
        class="edit_box"
        @click="show_ds(1)"
        :disabled="agree_Compensation"
        :class="agree_Compensation === false ? 'edit_box1' : 'edit_box'"
      >
        上架商品
      </el-button>
      <el-button
        class="edit_box"
        @click="show_ds(2)"
        :disabled="agree_Compensation"
        :class="agree_Compensation === false ? 'edit_box1' : 'edit_box'"
      >
        下架商品
      </el-button>
      <el-button
        class="edit_box"
        @click="show_ds(3)"
        :disabled="agree_Compensation"
        :class="agree_Compensation === false ? 'edit_box1' : 'edit_box'"
        v-if="tab > 1"
      >
        删除商品
      </el-button>
      <div class="edit_hou">
        已选商品<span v-if="goods_len > 0">({{ goods_len }})</span>
      </div>
    </div>
    <!-- 商品列表 -->
    <div class="kkk">
      <table class="goods_list">
        <!-- 表头 -->
        <thead class="list_hd">
          <th class="one">
            <el-checkbox
              class="checkbox"
              v-model="checkedAll"
              @change="handleCheckAllChange"
            >
              商品
            </el-checkbox>
          </th>
          <th class="it">价格</th>
          <th class="it">库存</th>
          <!-- <th class="it">销量</th> -->
          <th class="it">创建时间</th>
          <th class="it">操作</th>
        </thead>
        <!-- 表单内容 -->
        <tbody class="list_text">
          <tr
            v-for="(item, index) in shop_list"
            :key="index"
            class="tr"
            style="border: 1px solid #e8e8e8"
          >
            <td class="gds_item">
              <el-checkbox
                class="checkbox"
                v-model="item.v1"
                @change="hra($event, index)"
              >
                <el-image
                  class="goods_tu"
                  :src="item.img_src"
                  :fit="'cover'"
                  :preview-src-list="item.img_list"
                  @click.prevent="a"
                ></el-image>
              </el-checkbox>
              <div class="goods_info">
                <div class="Trade_name">{{ item.title }}</div>
                <div class="goods_id">ID:{{ item.id }}</div>
                <div class="di">
                  <div class="type">{{ item.type_title }}</div>
                  <div class="type">{{ item.brand }}</div>
                  <div class="zhe" v-if="item.reduction == 1">折</div>
                  <div class="baoyou" v-if="item.freight == 0">包邮</div>
                </div>
              </div>
            </td>
            <td>
              <div class="price">￥{{ item.price }}<span v-if="item.price != item.maxprice"> - {{item.maxprice}}</span></div>
            </td>
            <td>
              <div class="stock">{{ item.total }}</div>
              <div
                style="width: 100%;text-align: center;"
                v-if="item.status == 1"
              >
                <el-button
                  type="primary"
                  size="mini"
                  @click="show_stock(item.id)"
                  >添加库存</el-button
                >
              </div>
            </td>
            <!-- <td>
                            <div class="sales_volume">186</div>
                        </td> -->
            <td>
              <div class="creat_time">
                <div class="time_txt">{{ item.create_time }}</div>
                <div class="goods_type" v-if="item.status == 0">正在审核中</div>
                <div
                  class="goods_type"
                  style="color: red"
                  v-else-if="item.status == -1"
                >
                  已被强制下架
                </div>
                <template v-else-if="item.status == 2">
                  <div class="goods_red">审核不通过</div>
                  <div class="goods_type1">原因：{{ item.reason }}</div>
                </template>
                <template v-else>
                  <template v-if="item.total > 0">
                    <div class="goods_type" v-if="item.shelves == 0">
                      出售中
                    </div>
                    <div class="goods_type1" v-if="item.shelves == 2">
                      仓库中
                    </div>
                    <div class="goods_type2" v-if="item.shelves == 1">预售</div>
                  </template>
                  <template v-else>
                    <div class="goods_type" style="color: red">库存不足</div>
                  </template>
                </template>
              </div>
              <div
                style="width: 100%; text-align: center"
                v-if="item.shelves == 2 && item.status == 1 && item.total > 10"
              >
                <el-button
                  type="primary"
                  size="mini"
                  @click="show_reply_comment(item.id)"
                  >设置预售</el-button
                >
              </div>
              <el-dialog
                class="bba"
                center
                custom-class="dialog"
                title="预售活动"
                :visible.sync="reply_comment"
              >
                <div class="bba_main">
                  <!-- 内容 -->
                  <div class="main_1">
                    <div class="box_txt">活动起止时间：</div>
                    <el-date-picker
                      v-model="act_start_end_time"
                      type="datetimerange"
                      range-separator="——"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      class="date_picker"
                    >
                    </el-date-picker>
                  </div>
                  <div style="max-height: 500px; overflow-y: auto">
                    <table class="btable" border="1">
                      <thead class="thead_list">
                        <th class="it1">商品属性</th>
                        <th class="it" style="width:100px">商品价格</th>
                        <th class="it" style="width:100px">预售价格</th>
                        <th class="it" style="width:100px">数量</th>
                        <th class="it" style="width:100px">图片</th>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in sale" :key="index">
                          <td>{{ item.title }}</td>
                          <td>{{ item.price }}</td>
                          <td>
                            <el-input
                              :placeholder="'请输入单价'"
                              v-model="item.book_price"
                            />
                          </td>
                          <td>{{ item.total }}</td>
                          <td>
                            <el-image
                              class="goods_img"
                              :src="item.img_url"
                              :fit="'cover'"
                              :preview-src-list="[item.img_url]"
                              @click.prevent="a"
                            ></el-image>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="footer_btn">
                  <el-button class="bt_cancel" @click="cancel_btn"
                    >取消</el-button
                  >
                  <el-button class="bt_confirm" @click="sale_confirm"
                    >确定</el-button
                  >
                  <!-- 事件名已经在下面给定了-->
                </div>
              </el-dialog>
              <el-dialog
                class="bba"
                center
                custom-class="dialog"
                title="添加库存"
                :visible.sync="isStock"
              >
                <div class="bba_main">
                  <div style="max-height: 500px; overflow-y: auto">
                    <table class="btable" border="1">
                      <thead class="thead_list">
                        <th class="it1">商品属性</th>
                        <th class="it" style="width:100px">商品价格</th>
                        <th class="it" style="width:100px"  v-if="putaway == 1">预售价格</th>
                        <th class="it" style="width:100px">数量</th>
                        <th class="it" style="width:100px">图片</th>
                      </thead>
                      <tbody>

                        <tr v-for="(item, index) in sale" :key="index">
                          <td>{{ item.title }}</td>
                          <td>{{ item.price }}</td>
                          <td v-if="putaway == 1">{{item.sale}}
                          </td>
                          <td>
                            <el-input v-model="item.total"></el-input>
                          </td>
                          <td>
                            <el-image
                              class="goods_img"
                              :src="item.img_url"
                              :fit="'cover'"
                              :preview-src-list="[item.img_url]"
                              @click.prevent="a"
                            ></el-image>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="footer_btn">
                  <el-button class="bt_cancel" @click="cancel_stock"
                    >取消</el-button
                  >
                  <el-button class="bt_confirm" @click="stock_confirm"
                    >确定</el-button
                  >
                </div>
              </el-dialog>
            </td>
            <td>
              <template v-if="item.status == -1 || item.status == 2">
                <span
                  class="form-btn btn-xs btn-normal"
                  @click="again(item.id)"
                  >重新上架</span
                >
                <span class="form-btn btn-xs" @click="onedit(item.id)"
                  >编辑商品</span
                >
                <span
                  class="form-btn btn-xs btn-danger"
                  @click="del_goods(item.id)"
                  >删除商品</span
                >
              </template>
              <template v-if="item.status == 0">
                <span class="form-btn btn-xs" @click="onedit(item.id)"
                  >编辑商品</span
                >
                <span
                  class="form-btn btn-xs btn-danger"
                  @click="del_goods(item.id)"
                  >删除商品</span
                >
              </template>
              <template v-if="item.status == 1">
                <template v-if="item.shelves == 0 || item.shelves == 1">
                  <span
                    class="form-btn btn-xs btn-danger"
                    @click="grounding(item.id, 1)"
                    >下架商品</span
                  >
                  <span
                    class="form-btn btn-xs btn-warm"
                    v-if="item.shelves == 1"
                    @click="cancle_ys(item.id)"
                    >取消预售</span
                  >
                </template>
                <template v-else>
                  <span
                    class="form-btn btn-xs btn-normal"
                    @click="grounding(item.id, 0)"
                    >上架商品</span
                  >
                  <span class="form-btn btn-xs" @click="onedit(item.id)"
                    >编辑商品</span
                  >
                  <span
                    class="form-btn btn-xs btn-danger"
                    @click="del_goods(item.id)"
                    >删除商品</span
                  >
                </template>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- 底部分页 -->
    <page-tab :page="page" :count="page_count" @change="change_page"></page-tab>

    <!-- 背景虚影 -->
    <div v-if="is_ds">
      <div class="goods_bg"></div>
      <div class="ca_box">
        <div class="ca_title" v-if="number === 1">上架架商品</div>
        <div class="ca_title" v-if="number === 2">下架商品</div>
        <div class="ca_title" v-if="number === 3">删除商品</div>
        <div class="ca_txt" v-if="number === 1">确定要上架商品吗？</div>
        <div class="ca_txt" v-if="number === 2">确定要下架商品吗？</div>
        <div class="ca_txt" v-if="number === 3">确定要删除商品吗？</div>
        <div class="ft_btn">
          <div class="cancle" @click="show_ds">取消</div>
          <div class="comfirm" @click="sjia_comfirm">确定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../../common/axios";
import pageTab from "../pages/index";

export default {
  components: {
    pageTab,
  },
  props: ["act"],
  data() {
    return {
      isStock:false,
      sale: [], //点开的列表
      putaway:'',
      goods_id: "",
      goods_len: 0,
      tab: 0, //选择宝贝状态
      value: "", // 订单状态选中的值
      checkedAll: false, //商品多选框
      is_show_checked1: false,
      number: "", //判断是上架商品/下架商品/删除商品
      is_ds: false, //是否下架商品
      goods_type: 2, //商品状态
      is_cr: 0, //判断是否是从创建优惠活动页面跳转过来的
      agree_Compensation: true, // 控制头部按钮是否禁用
      page: 1,
      page_count: 1,
      shop_title: "",
      reply_comment: false, //
      act_start_end_time: [],
      head_list: [
        {
          id: 0,
          name: "全部宝贝",
        },
        {
          id: 1,
          name: "出售中的宝贝",
        },
        {
          id: 2,
          name: "仓库中的宝贝",
        },
        {
          id: 3,
          name: "审核中的宝贝",
        },
        {
          id: 4,
          name: "已被下架的宝贝",
        },
        {
          id: 5,
          name: "审核不通过的宝贝",
        },
      ],
      shop_list: [],
    };
  },
  mounted() {
    this.get_goods();
  },
  methods: {
    cancel_stock(){
      this.isStock = false
    },
    stock_confirm(){
      let arr = []
       this.sale.map((item) => {
        let a = {
          attribute_id: item.attribute_id,
          total: item.total,
        };
        arr.push(a);
      });
      console.log(arr)
      let url = "/adminshop/goods/add_stock.html";
      let data = {
        id: this.goods_id,
        params: arr,
      };
      axios.post(url, data).then((res) => {
        let success = res.success;
        let reslut = res.data;
        if (success) {
          if (reslut.status == 1) {
            this.$message({
              message: reslut.msg,
              type: "success",
            });
            this.isStock = false
            this.get_goods(this.page);
          } else {
            this.$message.error(reslut.msg);
          }
        }
      });
    },
    again(id){
      this.$confirm("是否重新上架商品？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let url = "/adminshop/goods/cxsj_goods.html";
          let data = {
            id,
          };
          axios.post(url, data).then((res) => {
            let success = res.success;
            let reslut = res.data;
            if (success) {
              if (reslut.status == 1) {
                this.$message({
                  message: reslut.msg,
                  type: "success",
                });
                this.get_goods(this.page);
              } else {
                this.$message.error(reslut.msg);
              }
            }
          });
        })
        .catch(() => {});
    },
    cancle_ys(id){
      this.$confirm("是否取消预售？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let url = "/adminshop/goods/cancel_yu.html";
          let data = {
            id,
          };
          axios.post(url, data).then((res) => {
            let success = res.success;
            let reslut = res.data;
            if (success) {
              if (reslut.status == 1) {
                this.$message({
                  message: reslut.msg,
                  type: "success",
                });
                this.get_goods(this.page);
              } else {
                this.$message.error(reslut.msg);
              }
            }
          });
        })
        .catch(() => {});
    },
    grounding(id,type) {
      let a = "是否上架该商品？"
      if(type ==1 ){
        a = "是否下架该商品？"
      }
      this.$confirm(a, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let url = "/adminshop/goods/oper_goods.html";
          let data = {
            id,
            type,
          };
          axios.post(url, data).then((res) => {
            let success = res.success;
            let reslut = res.data;
            if (success) {
              if (reslut.status == 1) {
                this.$message({
                  message: reslut.msg,
                  type: "success",
                });
                this.get_goods(this.page);
              } else {
                this.$message.error(reslut.msg);
              }
            }
          });
        })
        .catch(() => {});
    },
    //删除商品
    del_goods(id) {
      this.$confirm("是否删除该商品?删除后无法恢复", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let url = "/adminshop/goods/del_goods.html";
          let data = {
            id,
          };
          axios.post(url, data).then((res) => {
            let success = res.success;
            let reslut = res.data;
            if (success) {
              if (reslut.status == 1) {
                this.$message({
                  message: reslut.msg,
                  type: "success",
                });
                this.get_goods(this.page);
              } else {
                this.$message.error(reslut.msg);
              }
            }
          });
        })
        .catch(() => {});
    },
    sale_confirm() {
      let start = "";
      let end = "";
      if (
        this.act_start_end_time != null &&
        this.act_start_end_time.length > 0
      ) {
        start = this.get_time(this.act_start_end_time[0]);
        end = this.get_time(this.act_start_end_time[1]);
      }
      let arr = [];
      this.sale.map((item) => {
        let a = {
          attribute_id: item.attribute_id,
          book_price: item.book_price,
        };
        arr.push(a);
      });
      console.log();
      let url = "/adminshop/goods/set_goods.html";
      let data = {
        id: this.goods_id,
        start_time: start,
        end_time: end,
        params: arr,
      };
      axios.post(url, data).then((res) => {
        let success = res.success;
        let reslut = res.data;
        if (success) {
          if (reslut.status == 1) {
            this.$message({
              message: reslut.msg,
              type: "success",
            });
            this.reply_comment = false;
            this.get_goods(this.page);
          } else {
            this.$message.error(reslut.msg);
          }
        }
      });
    },
    //获取时间
    get_time(data) {
      var year = data.getFullYear(); //年
      var month = data.getMonth() + 1; //月
      var day = data.getDate(); //日
      var hh = data.getHours(); //时
      var mm = data.getMinutes(); //分
      let ss = data.getSeconds();
      var clock = year + "-";
      if (month < 10) clock += "0";
      clock += month + "-";
      if (day < 10) clock += "0";
      clock += day + " ";
      if (hh < 10) clock += "0";
      clock += hh + ":";
      if (mm < 10) clock += "0";
      clock += mm + ":";
      if (ss < 10) clock += "0";
      clock += ss;
      return clock;
    },
    cancel_btn() {
      // 回复评价弹框取消按钮事件
      this.reply_comment = !this.reply_comment;
    },
    show_stock(id){
      this.isStock = !this.isStock
      this.goods_id = id;
      let url = "/adminshop/goods/get_goods.html";
      let data = {
        id,
      };
      axios.post(url, data).then((res) => {
        let success = res.success;
        let reslut = res.data;
        if (success) {
          this.sale = reslut.params;
          this.putaway = reslut.putaway
          console.log(this.sale);
        }
      });
    },
    show_reply_comment(id) {
      this.goods_id = id;
      this.reply_comment = !this.reply_comment;
      let url = "/adminshop/goods/get_goods.html";
      let data = {
        id,
      };
      axios.post(url, data).then((res) => {
        let success = res.success;
        let reslut = res.data;
        if (success) {
          this.sale = reslut.params;
          console.log(this.sale);
        }
      });
    },
    onedit(id) {
      this.$router.push({
        path: "/goods/edit_goods",
        query: {
          id: id,
        },
      });
    },
    sub_search() {
      this.page = 1;
      this.get_goods();
    },
    get_goods(page = 0) {
      if (page == 0) {
        page = 1;
      }
      let title = this.shop_title;
      let status = this.value;
      this.page = page;
      let shop_list = [];
      let url = "/adminshop/goods/index.html";
      let data = {
        page,
        title,
        status,
      };
      axios.post(url, data).then((res) => {
        let success = res.success;
        let data = res.data;
        if (success) {
          let list = data.list;
          if (list != undefined && list != null && list != "") {
            for (var i in list) {
              shop_list[i] = list[i];
              shop_list[i].v1 = false;
            }
            // shop_list = list;
          }
          this.page_count = parseInt(data.pages);
        } else {
          console.log(res.error);
        }
        this.shop_list = shop_list;
      });
    },
    change_item(id) {
      if (this.tab == id) {
        return;
      }
      this.tab = id;
      this.value = id;
      this.page = 1;
      this.get_goods();
       this.$refs.tree.scrollTop = 0
      this.checkedAll = false;
      this.agree_Compensation = true;
      this.goods_len = 0;
    },
    change_edit(index) {
      console.log("index", index);
      this.tab1 = index;
    },
    change_page(e) {
      let page = e.value;
      this.page = page;
      this.get_goods(page);
      this.$refs.tree.scrollTop = 0;
      this.checkedAll = false;
      this.agree_Compensation = true;
      this.goods_len = 0;
    },
    sjia_comfirm() {
      let a = [];
      this.shop_list.map((item) => {
        if (item.v1 == true) {
          a.push(item.id);
        }
      });
      a = a.toString();
      if (this.number == 1) {
        let url = "/adminshop/goods/alloper_goods.html";
        this.eidt_goods(url, a, 0);
      }
      if (this.number == 2) {
        let url = "/adminshop/goods/alloper_goods.html";
        this.eidt_goods(url, a, 1);
      }
      if (this.number == 3) {
        let url = "/adminshop/goods/alldel_goods.html";
        this.eidt_goods(url, a, 2);
      }
    },
    //上架下架删除
    eidt_goods(url, a, type) {
      let data = {
        id: a,
        type: type,
      };
      axios.post(url, data).then((res) => {
        let success = res.success;
        let data = res.data;
        if (success) {
          if (data.status == 1) {
            console.log(data);
            this.$message({
              message: data.msg,
              type: "success",
            });
            this.show_ds();
            this.get_goods(this.page);
            this.agree_Compensation = true;
            this.goods_len = 0;
            this.checkedAll = false;
          } else {
            this.$message({
              message: data.msg,
              type: "error",
            });
          }
        } else {
          console.log(res.error);
        }
      });
    },
    show_ds(num) {
      this.is_ds = !this.is_ds;
      this.number = num;
    },
    handleCheckAllChange(e) {
      this.checkedAll = e;
      if (this.checkedAll == true) {
        this.shop_list.forEach((item) => {
          this.$set(item, "v1", true);
          this.agree_Compensation = false;
          this.goods_len = this.shop_list.length;
        });
      } else {
        this.shop_list.forEach((item) => {
          this.$set(item, "v1", false);
          this.agree_Compensation = true;
          this.goods_len = 0;
        });
      }
    },
    hra(e, index) {
      //选中其中一个退款订单，触发相对应订单能操作的按钮
      this.shop_list[index].v1 = e;
      let a = this.shop_list.filter((item) => item.v1 == true).length;
      console.log(a);
      this.goods_len = a;
      if (a > 0) {
        this.agree_Compensation = false;
      } else {
        this.agree_Compensation = true;
      }
      if (a == this.shop_list.length) {
        this.checkedAll = true;
      } else {
        this.checkedAll = false;
      }
    },
    a() {},
  },
};
</script>

<style scoped>
@import "./mygoods.css";
.bba /deep/ .el-dialog__header {
  padding: 15px 0;
}

.bba /deep/ .el-dialog__title {
  font-weight: bold;
  line-height: 0;
}
</style>
